import React, { useState } from 'react';
import { storage } from "../firebase";

const SingleFileUpload = ({ setDownloadUrl, setIsUploading }) => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const uploadFile = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) {
            return;
        }

        // Check file size (2MB = 2097152 bytes)
        if (selectedFile.size > 2097152) {
            alert('La taille du fichier dépasse 2 Mo. Veuillez sélectionner un fichier plus petit.');
            return; // Prevent the upload
        }

        setFile(selectedFile);
        setFileName(selectedFile.name);
        uploadToFirebase(selectedFile);
    };

    const uploadToFirebase = (selectedFile) => {
        const randomNum = Math.random() * 10000;
        const uploadTask = storage.ref(`files/${selectedFile.name}${randomNum}`).put(selectedFile);
        setIsUploading(true);
        uploadTask.on(
            "state_changed",
            snapshot => {
                const progress = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                console.log(progress);
            },
            error => {
                console.log(error);
                setIsUploading(false);
            },
            () => {
                storage
                    .ref("files")
                    .child(`${selectedFile.name}${randomNum}`)
                    .getDownloadURL()
                    .then(url => {
                        setDownloadUrl(url);
                        setIsUploading(false);
                        console.log('File URL:', url);
                    });
            }
        );
    };

    return (
        <form className="my-3">
            {fileName && (
                <div className="form-group">
                    <p>Fichier sélectionné: {fileName}</p>
                </div>
            )}

            <div className="form-group">
                <input type="file" className="form-control" onChange={uploadFile} />
            </div>
        </form>
    );
};

export default SingleFileUpload;
