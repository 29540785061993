import React, { useState } from 'react';
import NavBar from "./components/NavBar";
import axios from 'axios';
import {useNavigate} from "react-router-dom";
// Import UI components as needed

const ImportUsers = () => {
    const [file, setFile] = useState(null);
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', file);

        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/import/${user.companyId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response);
            navigate('/list-users');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="container align-items-center">
            <NavBar />
            <form onSubmit={handleSubmit} className='my-4'>
                <input type="file" onChange={handleFileChange} />
                <button type="submit" className="btn btn-primary">Import Users</button>
            </form>
        </div>
    );
};

export default ImportUsers;
