import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import bcrypt from 'bcryptjs';
import CryptoJS from 'crypto-js';
import { isValidToken } from './middleware/RequireAuth';
import jwt_decode from "jwt-decode";
import axios from 'axios';

const LoginScreen = () => {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const users = {
        adminCSE: { passwordHash: bcrypt.hashSync("K9$Fd9@", 10), role: "user" },
        admin: { passwordHash: bcrypt.hashSync("s@R%L10$", 10), role: "superadmin" }
    };

    const generateToken = (login) => {
        const timestamp = new Date().getTime();
        const baseToken = `${login}-${timestamp}`;
        // Encrypt the token
        return CryptoJS.AES.encrypt(baseToken, "m%$dF7@fM%").toString();
    };

    const getRole = (isAdmin, isSuperAdmin) => {
        if(isSuperAdmin)
            return "superadmin"

        if(isAdmin)
            return "user"

        return undefined
    }

    const navigate = useNavigate()
    const handleSubmit = async () => {
        const user = users[login];

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/login`, {
                email: login,
                password: password
            });

            const { user, token } = data;
            const decoded = jwt_decode(token);
            //alert(`user: ${user}\nadmin: ${decoded.isAdmin}\nsuperAdmin: ${decoded.isSuperAdmin}\nuserId: ${decoded.userId}\ncompanyId: ${decoded.companyId}\nexpiry: ${decoded.exp}`);
            if (user && token) {
                if(!decoded.isAdmin && !decoded.isSuperAdmin){
                    alert('User must be Admin or SuperAdmin');
                    navigate('/');
                }
                const role = getRole(decoded.isAdmin, decoded.isSuperAdmin);
                const companyId = decoded.companyId;
                localStorage.setItem('user', JSON.stringify({ login, role: role, token, companyId }));
                if(decoded.isAdmin){
                    navigate('/create-commerce');
                }
                if(decoded.isSuperAdmin){
                    navigate('/create-actu');
                }
                navigate('/');
            } else {
                alert('Mauvais login ou mot de passe');
            }
        } catch (e) {
            alert('Mauvais login ou mot de passe');
        }        
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token && isValidToken(user.token)) {
            navigate('/list-commerces');
        }
    }, [navigate]);

    return (
        <div>
            <h5 style={{ textAlign: 'center' }}>Formulaire de connection</h5>
            <form action="" className='connection-form'>
                <div>
                    <div>
                        <label htmlFor="login" className="form-label">Login</label>
                    </div>
                    <input type="text" id="login" value={login} className="form-control" onChange={(e) => setLogin(e.target.value)} />
                </div>

                <div>
                    <div>
                        <label htmlFor="password" className="form-label">Mot de passe</label>
                    </div>

                    <input type="password" id="password" value={password} className="form-control" onChange={(e) => setPassword(e.target.value)} />
                </div>

                <button onClick={handleSubmit} type="button" className="btn btn-primary my-3">Se connecter</button>
            </form>
        </div>
    );
};

export default LoginScreen;
