import React, { useState, useCallback, useRef } from "react";
import axios from 'axios';
import SingleImageUpload from "./components/SingleImageUpload";
import SingleFileUpload from "./components/SingleFileUpload";
import NavBar from "./components/NavBar";
import { sendNotificationToCompanyUsers } from './utils/functions/notifications';
import ReactQuill from 'react-quill';
import { storage } from "./firebase";
import 'react-quill/dist/quill.snow.css';

const CreateActualiteScreen = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [richDescription, setRichDescription] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const reactQuillRef = useRef(null);

    const handleSubmit = async () => {
        if (title && description && imageUrl && richDescription) {
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/actualites`, {
                    title,
                    description : richDescription,
                    image: imageUrl,
                    file: fileUrl,
                    commerce: process.env.REACT_APP_ADMIN_ID,
                    companyId: user.companyId
                });
                setShowSuccessModal(true);
                setTimeout(() => setShowSuccessModal(false), 3000);
                console.log(data);
                //replace description
                data.description = description;
                sendNotificationToCompanyUsers(title, description, imageUrl, data, 'ActualiteDetailScreen', user.companyId);
            } catch (e) {
                console.log(e);
                setShowErrorModal(true);
                setTimeout(() => setShowErrorModal(false), 3000);
            }
        }
        else {
            setShowErrorModal(true);
        }
    };

    const modules = {
        toolbar: {
            container: [
                [{ 'header': '1' }, { 'header': '2' }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean']
            ]
        }
    };

    console.log('fileUrl', fileUrl);

    return (
        <div className="App container">
            <NavBar />
            <h5 className="mt-3">Créer une actualité</h5>

            <p className="mt-3 mb-1">Titre</p>
            <input required className="form-control" type="text" placeholder="Titre" onChange={(e) => setTitle(e.target.value)} />

            <p className="mt-3 mb-1">Description notification</p>
            <textarea required className="form-control" rows="4" placeholder="Description" onChange={(e) => setDescription(e.target.value)}></textarea>

            <p className="mt-3 mb-1">Description actualité</p>
            <ReactQuill
                ref={reactQuillRef}
                value={richDescription}
                onChange={setRichDescription}
                modules={modules}
            />

            <p className="mt-3 mb-1">Image</p>
            <SingleImageUpload setDownloadUrl={setImageUrl} setIsUploading={setIsUploading} />

            <p className="mt-3 mb-1">Fichier (optionnel, max 2 Mo)</p>
            <SingleFileUpload setDownloadUrl={setFileUrl} setIsUploading={setIsUploading} />

            <button
                onClick={handleSubmit}
                className="btn btn-primary my-3"
                disabled={!imageUrl || !title || !description || isUploading}
            >
                {isUploading ? "Chargement..." : "Soumettre"}
            </button>

            {showSuccessModal && (
                <div className="modal show fade" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Success</h5>
                            </div>
                            <div className="modal-body">
                                <p>Actualité créée avec succès!</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showErrorModal && (
                <div className="modal show fade" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Error</h5>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Une erreur s'est produite lors de la création de l'actualité.
                                    Veuillez vérifier que vous avez rempli tous les champs et réessayer.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateActualiteScreen;
