import React from 'react';
import { Navigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import jwt_decode from "jwt-decode";

export const isValidToken = (token) => {
    try {
            const decodedToken = jwt_decode(token);
            const currentTime = Date.now() / 1000;
            //alert(`decodedToken.exp: ${decodedToken.exp}\n>\ncurrentTime: ${currentTime}`)
            return decodedToken.exp > currentTime;
    } catch (e) {
        return false; // Token is invalid
    }
};

export const RequireAuth = ({ children }) => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user || !user.token || !isValidToken(user.token)) {
        return <Navigate to="/" />;
    }

    return children;
};